/* tslint:disable */
/* eslint-disable */
/**
 * StGeorge Kirch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface FileDetails
 */
export interface FileDetails {
    /**
     * 
     * @type {number}
     * @memberof FileDetails
     */
    'created_at'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDetails
     */
    'filename': string;
    /**
     * 
     * @type {number}
     * @memberof FileDetails
     */
    'modified_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileDetails
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface StorageFiles
 */
export interface StorageFiles {
    /**
     * 
     * @type {Array<FileDetails>}
     * @memberof StorageFiles
     */
    'files': Array<FileDetails>;
    /**
     * 
     * @type {number}
     * @memberof StorageFiles
     */
    'remaining_bytes': number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {ValidationErrorDetail}
     * @memberof ValidationError
     */
    'detail'?: ValidationErrorDetail;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ValidationErrorDetail
 */
export interface ValidationErrorDetail {
    /**
     * 
     * @type {ValidationErrorDetailLocation}
     * @memberof ValidationErrorDetail
     */
    '&lt;location&gt;'?: ValidationErrorDetailLocation;
}
/**
 * 
 * @export
 * @interface ValidationErrorDetailLocation
 */
export interface ValidationErrorDetailLocation {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationErrorDetailLocation
     */
    '&lt;field_name&gt;'?: Array<string>;
}

/**
 * AuthRoutesApi - axios parameter creator
 * @export
 */
export const AuthRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthDeleteDelete: async (email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is Logged
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthIsLoggedGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/is_logged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log In
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthLogInPost: async (email: string, password: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiV1AuthLogInPost', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiV1AuthLogInPost', 'password', password)
            const localVarPath = `/api/v1/auth/log_in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log Out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthLogOutPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/log_out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthRegisterPost: async (email: string, password: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiV1AuthRegisterPost', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiV1AuthRegisterPost', 'password', password)
            const localVarPath = `/api/v1/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthRoutesApi - functional programming interface
 * @export
 */
export const AuthRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AuthDeleteDelete(email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AuthDeleteDelete(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthRoutesApi.apiV1AuthDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Is Logged
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AuthIsLoggedGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AuthIsLoggedGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthRoutesApi.apiV1AuthIsLoggedGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Log In
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AuthLogInPost(email: string, password: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AuthLogInPost(email, password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthRoutesApi.apiV1AuthLogInPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Log Out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AuthLogOutPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AuthLogOutPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthRoutesApi.apiV1AuthLogOutPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Register
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AuthRegisterPost(email: string, password: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AuthRegisterPost(email, password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthRoutesApi.apiV1AuthRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthRoutesApi - factory interface
 * @export
 */
export const AuthRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthDeleteDelete(email?: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1AuthDeleteDelete(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is Logged
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthIsLoggedGet(options?: any): AxiosPromise<any> {
            return localVarFp.apiV1AuthIsLoggedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log In
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthLogInPost(email: string, password: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1AuthLogInPost(email, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log Out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthLogOutPost(options?: any): AxiosPromise<any> {
            return localVarFp.apiV1AuthLogOutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthRegisterPost(email: string, password: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1AuthRegisterPost(email, password, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthRoutesApi - object-oriented interface
 * @export
 * @class AuthRoutesApi
 * @extends {BaseAPI}
 */
export class AuthRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Delete
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthRoutesApi
     */
    public apiV1AuthDeleteDelete(email?: string, options?: RawAxiosRequestConfig) {
        return AuthRoutesApiFp(this.configuration).apiV1AuthDeleteDelete(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is Logged
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthRoutesApi
     */
    public apiV1AuthIsLoggedGet(options?: RawAxiosRequestConfig) {
        return AuthRoutesApiFp(this.configuration).apiV1AuthIsLoggedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log In
     * @param {string} email 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthRoutesApi
     */
    public apiV1AuthLogInPost(email: string, password: string, options?: RawAxiosRequestConfig) {
        return AuthRoutesApiFp(this.configuration).apiV1AuthLogInPost(email, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log Out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthRoutesApi
     */
    public apiV1AuthLogOutPost(options?: RawAxiosRequestConfig) {
        return AuthRoutesApiFp(this.configuration).apiV1AuthLogOutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register
     * @param {string} email 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthRoutesApi
     */
    public apiV1AuthRegisterPost(email: string, password: string, options?: RawAxiosRequestConfig) {
        return AuthRoutesApiFp(this.configuration).apiV1AuthRegisterPost(email, password, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<any> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StorageRoutesApi - axios parameter creator
 * @export
 */
export const StorageRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download File
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StorageDownloadGet: async (filename: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiV1StorageDownloadGet', 'filename', filename)
            const localVarPath = `/api/v1/storage/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StorageFilesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/storage/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove File
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StorageRemoveDelete: async (filename: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiV1StorageRemoveDelete', 'filename', filename)
            const localVarPath = `/api/v1/storage/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StorageUploadPost: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1StorageUploadPost', 'file', file)
            const localVarPath = `/api/v1/storage/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageRoutesApi - functional programming interface
 * @export
 */
export const StorageRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download File
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StorageDownloadGet(filename: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StorageDownloadGet(filename, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageRoutesApi.apiV1StorageDownloadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StorageFilesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageFiles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StorageFilesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageRoutesApi.apiV1StorageFilesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove File
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StorageRemoveDelete(filename: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StorageRemoveDelete(filename, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageRoutesApi.apiV1StorageRemoveDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StorageUploadPost(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StorageUploadPost(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StorageRoutesApi.apiV1StorageUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StorageRoutesApi - factory interface
 * @export
 */
export const StorageRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Download File
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StorageDownloadGet(filename: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1StorageDownloadGet(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StorageFilesGet(options?: any): AxiosPromise<StorageFiles> {
            return localVarFp.apiV1StorageFilesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove File
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StorageRemoveDelete(filename: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1StorageRemoveDelete(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StorageUploadPost(file: File, options?: any): AxiosPromise<FileDetails> {
            return localVarFp.apiV1StorageUploadPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageRoutesApi - object-oriented interface
 * @export
 * @class StorageRoutesApi
 * @extends {BaseAPI}
 */
export class StorageRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Download File
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRoutesApi
     */
    public apiV1StorageDownloadGet(filename: string, options?: RawAxiosRequestConfig) {
        return StorageRoutesApiFp(this.configuration).apiV1StorageDownloadGet(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRoutesApi
     */
    public apiV1StorageFilesGet(options?: RawAxiosRequestConfig) {
        return StorageRoutesApiFp(this.configuration).apiV1StorageFilesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove File
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRoutesApi
     */
    public apiV1StorageRemoveDelete(filename: string, options?: RawAxiosRequestConfig) {
        return StorageRoutesApiFp(this.configuration).apiV1StorageRemoveDelete(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload File
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRoutesApi
     */
    public apiV1StorageUploadPost(file: File, options?: RawAxiosRequestConfig) {
        return StorageRoutesApiFp(this.configuration).apiV1StorageUploadPost(file, options).then((request) => request(this.axios, this.basePath));
    }
}




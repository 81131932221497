import React, { useState } from 'react';

// Meant to be used for smaller devices only
export const InfoModal = ({ show, title, message, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg relative max-w-xs mx-auto shadow-lg">
        <button
          className="absolute top-2 right-2 text-gray"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-lg font-bold mb-4">{title}</h2>
        <p className="text-gray-700 whitespace-pre-line text-sm text-left">{message}</p>
      </div>
    </div>
  );
};
import { InfoModal } from "@components/InfoModal";
import { formatTimestamp, prettyPrintBytes, getWindowDimensions } from "@components/utils"
import React, { useEffect, useState } from "react"


export function LibraryTable({ storageData, handleDownload, handleRemove }): JSX.Element {
    const { width, height } = getWindowDimensions();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    if (width < 1024) {
        return (
            <div className="mx-auto overflow-y-auto max-h-[60vh] rounded-2xl border-2 border-c-main">
                <table className="min-w-full">
                    <thead className="bg-c-background">
                        <tr>
                            <th className="py-2 px-4 border-b w-1/2">Dateiname</th>
                            <th className="py-2 px-4 border-b">Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storageData && storageData.files.map((file, idx) => (
                            <tr key={idx} className="text-center">
                                <td className="py-2 px-4 border-b break-all w-1/2">{file.filename}</td>
                                <td className="py-2 px-4 border-b">
                                    <button
                                        className="focus:outline-none hover:text-blue-500 transition duration-300"
                                        aria-label="Download"
                                        onClick={() => handleDownload(file.filename)}
                                    >
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>
                                    <button
                                        className="focus:outline-none hover:text-red-500 transition duration-300 ml-2"
                                        aria-label="Remove"
                                        onClick={() => handleRemove(file.filename, idx)}
                                    >
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>

                                    <button
                                        className="focus:outline-none hover:text-red-500 transition duration-300 ml-2 has-tooltip"
                                        aria-label="Show Info"
                                        onClick={() => {
                                            setIsModalOpen(true)
                                            setModalMessage(`Dateigröße: ${prettyPrintBytes(file.size)}\nErstellungsdatum: ${formatTimestamp(file.created_at)}\n Änderungsdatum: ${formatTimestamp(file.modified_at)}`)
                                        }
                                        }
                                    >

                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM12 16v-4M12 8h.01" />
                                        </svg>
                                    </button>

                                    <InfoModal show={isModalOpen} title={"Dateiinformationen"} message={modalMessage} onClose={() => setIsModalOpen(false)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    } else {
        return (
            <div className="mx-auto overflow-y-auto max-h-[60vh] rounded-2xl border-2 border-c-main">
                <table className="min-w-full">
                    <thead className="bg-c-background">
                        <tr>
                            <th className="py-2 px-4 border-b">Dateiname</th>
                            <th className="py-2 px-4 border-b">Dateigröße</th>
                            <th className="py-2 px-4 border-b">Erstellungsdatum</th>
                            <th className="py-2 px-4 border-b">Änderungsdatum</th>
                            <th className="py-2 px-4 border-b">Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storageData && storageData.files.map((file, idx) => (
                            <tr key={idx} className="text-center">
                                <td className="py-2 px-4 border-b">{file.filename}</td>
                                <td className="py-2 px-4 border-b">{prettyPrintBytes(file.size)}</td>
                                <td className="py-2 px-4 border-b">{formatTimestamp(file.created_at)}</td>
                                <td className="py-2 px-4 border-b">{formatTimestamp(file.modified_at)}</td>
                                <td className="py-2 px-4 border-b">
                                    <button
                                        className="focus:outline-none hover:text-blue-500 transition duration-300"
                                        aria-label="Download"
                                        onClick={() => handleDownload(file.filename)}
                                    >
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>
                                    <button
                                        className="focus:outline-none hover:text-red-500 transition duration-300 ml-2"
                                        aria-label="Remove"
                                        onClick={() => handleRemove(file.filename, idx)}
                                    >
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

}
import { AuthContext } from "@components/AuthContext";
import React, { useEffect, useContext, useState, useRef } from "react";
import { LOCAL_API_SERVER, PROD_API_SERVER } from "../../consts";
import * as API from '../../generated/api';
import { LibraryTable } from "@components/LibraryTable";
import { prettyPrintBytes } from '@components/utils'


export const Bibliothek = () => {
    const fileInputRef = useRef(null);

    const authContext = useContext(AuthContext);
    const [storageData, setStorageData] = useState<API.StorageFiles>(null);
    // Hardcoded file data

    const storageApi = new API.StorageRoutesApi(undefined, process.env.APP_ENV == 'DEV' ? LOCAL_API_SERVER : PROD_API_SERVER)

    // Event handlers for download and remove actions
    const handleDownload = (fileName: string) => {
        // Placeholder for download logic
        // console.log(`Downloading file: ${fileName}`);

        // https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
        (async () => {
            const response = await storageApi.apiV1StorageDownloadGet(fileName, { withCredentials: true, responseType: 'blob' })
                .then((response) => {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
        })()
    };

    const handleRemove = (fileName: string, index: number) => {
        // Placeholder for remove logic
        // console.log(`Removing file: ${fileName}`);
        (async () => {
            const response = await storageApi.apiV1StorageRemoveDelete(fileName, { withCredentials: true })
            if (response.status == 200) {
                const updatedFiles = [...storageData.files];
                const removedFile = updatedFiles.splice(index, 1)[0];

                setStorageData((prevState) => ({
                    ...prevState,
                    files: updatedFiles,
                    remaining_bytes: prevState.remaining_bytes + removedFile.size
                }));
            }
        })()
    };

    const handleButtonClick = () => {
        // Trigger the click event of the hidden file input
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        // console.log('Selected file:', selectedFile);

        try {
            // Check if the file already exists in the current file list
            const existingFileIndex = storageData.files.findIndex(file => file.filename === selectedFile.name);

            if (existingFileIndex !== -1) {
                // File with the same name already exists, replace it
                const updatedFiles = [...storageData.files];
                const replacedFile = await storageApi.apiV1StorageUploadPost(selectedFile, { withCredentials: true });

                updatedFiles[existingFileIndex] = replacedFile.data;

                setStorageData((prevState) => ({
                    ...prevState,
                    files: updatedFiles,
                    remaining_bytes: prevState.remaining_bytes - (replacedFile.data.size - storageData.files[existingFileIndex].size)
                }));
            } else {
                // File does not exist, add it to the list
                const response = await storageApi.apiV1StorageUploadPost(selectedFile, { withCredentials: true });

                if (response.status === 200) {
                    // console.log('Uploaded file');
                    const filedetails = response.data;
                    const updatedFiles = [...storageData.files];
                    updatedFiles.push(filedetails);

                    setStorageData((prevState) => ({
                        ...prevState,
                        files: updatedFiles,
                        remaining_bytes: prevState.remaining_bytes - filedetails.size
                    }));
                }
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };


    useEffect(() => {
        (async () => {
            const response = await storageApi.apiV1StorageFilesGet({ withCredentials: true })
            if (response.status == 200) {
                const storageData: API.StorageFiles = response.data
                setStorageData(storageData)
            }
        })()
    }, [])

    return (
        <div className="max-w-screen-xl min-w-[100%]">
            <div className=""></div>
            <div>
                <h2 className="text-4xl font-bold mb-4">Bibliothek</h2>
                <button className="ml-auto px-2 py-2 bg-c-green text-white rounded flex justify-center items-center" onClick={handleButtonClick}>
                    Hochladen
                    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M5 15l7-7 7 7" />
                    </svg>
                </button>

                {/* Hidden file input */}
                <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </div>

            {
                storageData &&
                <h2 className="text-xl p-2"> Verfügbarer Speicherplatz: {prettyPrintBytes(storageData.remaining_bytes)} </h2>
            }

            <LibraryTable storageData={storageData} handleDownload={handleDownload} handleRemove={handleRemove}/>

        </div>
    )
}